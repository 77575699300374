import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    // root
    const body = document.querySelector('.main')
    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    )
    // window.scrollTo(0, 0)
  }, [pathname])

  return null
}
