import React from 'react'
import ContactForm from '../components/ContactForm'
// import image from '../assets/images/other/pianos.jpg'
// import image2 from '../assets/images/other/organ.jpg'

export const Lessons = () => {
  return (
    <section className='main lessons-section'>
      <div
        className='section-center initial-section'
        style={{ maxWidth: '100%' }}
      >
        <div className='flex-container' style={{ gap: '1rem 0rem' }}>
          <article className='lessons-info'>
            <h2>Lesson Info </h2>
            <p>
              Lessons and lesson durations are scheduled on an individual basis.
              The new school year starts September 1st, and students are
              expected to continue actively studying until June 25. In the
              summer, lessons are optional, and continue on a more flexible
              schedule. During the summer lesson period, more highly motivated
              students for whom results matter most take higher priority.
              Regular practice at home, and the support of the parents, are both
              indispensable to the success and musical development of the pupil.
            </p>
            <p>
              Lessons typically take place once or twice a week, and are around
              45 minutes. This will depend on the age of the student as well as
              their desired goals. My experience and professionalism allows me
              to work with young kids through adults, from level Beginner
              through Advanced. So please don't be shy, I welcome students of
              all levels!
            </p>
            {/* <p>
              As a teacher, I can proudly say that I have been very fortunate in
              my life to receive an excellent musical education at the Moscow
              State Tchaikovsky Conservatory (including a PhD). I had the
              opportunity to learn from outstanding musicians who passed on to
              me the love of music and the profession to which I have devoted
              more than 30 years, making me highly qualified in teaching piano,
              music theory, and music composition.
            </p> */}
            {/* I am highly qualified in teaching piano, music theory and music
              composition.  */}
            <p>
              My music academy offers unique learning opportunities - two Yamaha
              and Kawai grand pianos of excellent quality. An organ on which we
              practice playing Baroque music, as well as various children's
              musical instruments and electronic instruments. And also in my
              academy there is a unique music library.
            </p>
            <p>
              Students of my music academy have every opportunity to develop
              their creative potential, taking an active part in competitions
              and festivals, concerts, master classes with famous
              pianists/professors. My students also regularly perform at
              Carnegie Hall as well as the Lincoln Center for the Performing
              Arts in New York City.
            </p>
          </article>
          {/* NEW */}
          <article style={{ marginBottom: '3rem' }} className='lesson-img'>
            <h3>Music Academy Policies</h3>
            <ol style={{ letterSpacing: '0.05rem' }}>
              <li>
                <span className='highlight'>Tuition</span> is paid on a monthly
                basis, and it is due on the last lesson of each month, or on the
                first lesson of each month.
              </li>
              <li>
                <span className='highlight'>Refunds and Credits</span>
                <ol
                  style={{
                    listStyleType: 'lower-alpha',
                    marginLeft: '1rem',
                    fontSize: '0.91rem',
                  }}
                >
                  <li>
                    <span className='highlight'>
                      Rescheduling lessons is allowed!
                    </span>{' '}
                    In the event you need to reschedule, please reach out about
                    it as soon as possible.
                  </li>
                  <li>
                    <span className='highlight'>
                      During government holidays and school vacations
                    </span>
                    , it is acceptable to{' '}
                    <span className='highlight'>
                      cancel or reschedule lessons.
                    </span>
                  </li>
                  <li>
                    Parents and students understand that{' '}
                    <span className='highlight'>
                      they are responsible for absences
                    </span>{' '}
                    which impact the learning process.
                  </li>
                  <li>
                    If such{' '}
                    <span className='highlight'>
                      absences without honorable reasons
                    </span>{' '}
                    become regular, the Academy reserves the right to stop
                    tutorship at any time.
                  </li>
                  <li>
                    <span className='highlight'>If I cancel a lesson</span>,
                    then I will immediately follow through with the student
                    and/or their legal guardian about either{' '}
                    <span className='highlight'>rescheduling the lesson</span>{' '}
                    or <span className='highlight'>giving a refund.</span>
                  </li>
                  <li>
                    <span className='highlight'>
                      No refunds are guaranteed for tardiness to a lesson
                    </span>
                    , if there is another scheduled lesson afterwards. If you
                    will be tardy, please send me a message so I can manage my
                    time more effectively.
                  </li>
                </ol>
              </li>
              <li>
                <span className='highlight'>Books</span> required for lessons
                must be purchased by the parents or the student. Please label
                the book with the name of the student.
              </li>
              <li>
                <span className='highlight'>Hygiene</span> is important. The
                student must have clean hands and clean clothes. The student’s
                fingernails must be neatly trimmed. Also check the dress code.
              </li>
              <li>
                <span className='highlight'>Parking</span> is available in any
                free space in front of the house. If at times our parking lot is
                full, please be understanding. I can advise you where to park.
                If you park in front of the neighbors’ houses, I kindly ask that
                you don’t drive onto their lawns.
              </li>
              <li>
                Please <span className='highlight'>be quiet</span> during the
                lessons (besides the music). If you have cell phone
                conversations, please do it outside.
              </li>
              <li>
                <span className='highlight'>Small children</span> who are
                awaiting older siblings may be in the studio during the time of
                a class. They may play with toys, draw, or study, as long as
                it’s quiet. Small children need to be under parental
                supervision, and must not interfere with the lesson.
              </li>
              <li>
                All academy students are required to perform in{' '}
                <span className='highlight'>annual recitals</span>.
              </li>
            </ol>
            {/* <div className='lesson-pic-container parent'>
              <img src={image} className='image1' alt='pianos' />
              <img src={image2} className='image2' alt='organ' />
            </div> */}
          </article>
          {/* <ContactForm /> */}
        </div>
        <div
          // style={{ marginTop: '1rem' }}
          className='flex-container flex-reverse'
        >
          <div>
            <h2>have any questions?</h2>
          </div>
          <ContactForm />
        </div>
      </div>
    </section>
  )
}
